import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import Image from '../components/ParallaxImageHandler';
import Seo from '../components/Seo';
import AnimatedBlob from '../components/AnimatedBlob';
import AnimatedPhone from '../components/AnimatedPhone';
import Banner from '../components/Banner/index.jsx';
import { Container, Row, Col } from 'react-bootstrap';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import FeatureCard from '../components/FeatureCard';
import WistiaPopoverVideo from '../components/WistiaPopoverVideo';
import { Waypoint } from 'react-waypoint';
import ShinyButton from '../components/ShinyButton';
import { throwSignupConversionEvent } from '../scripts/utils';

import './style.scss';

const IndexPage = () => {
    //
    const [animateHeader, setAnimateHeader] = useState(false);
    const [animatePhone, setAnimatePhone] = useState(false);
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'ProfessionalService',
        name: 'Glasshive',
        address: {
            '@type': 'PostalAddress',
            streetAddress: '900 W Bethany Dr, Suite 240',
            addressLocality: 'Allen',
            addressRegion: 'TX',
            postalCode: '75013',
            addressCountry: 'US',
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: 33.0913173,
            longitude: -96.6842749,
        },
        url: 'https://glasshive.com/',
        telephone: '+14699652557',
        priceRange: '$',
        openingHoursSpecification: [
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                ],
                opens: '8:30',
                closes: '17:30',
            },
        ],
    };

    useEffect(() => {
        document.querySelector('.stats-view-box').classList.add('animate');
        document.querySelector('.mobile-stats-view').classList.add('animate');
    });

    return (
        <ParallaxProvider>
            <Layout>
                <Seo title="Home" schemaMarkUp={schema} />
                <script
                    async
                    src="https://tag.simpli.fi/sifitag/98575c70-11df-013b-526e-0cc47a1f72a4"
                ></script>
                <div className="home-page">
                    <Container
                        className={`white-swish-top container-1 ${
                            animateHeader ? 'animate' : ''
                        }`}
                    >
                        <Waypoint
                            onEnter={() => {
                                setAnimateHeader(true);
                            }}
                        />
                        <h1>Say Hello to GlassHive</h1>
                        <p className="hero-subtext">
                            A super sweet sales & marketing app for IT service
                            providers
                        </p>
                        <div className="flex justify-center hero-btns margin-top-50">
                            <ShinyButton
                                className="btn pink create-free-account-button"
                                onClick={() =>
                                    throwSignupConversionEvent(
                                        'https://app.glasshive.com/Account/Login?s=signup'
                                    )
                                }
                            >
                                Try GlassHive for Free
                            </ShinyButton>
                            <WistiaPopoverVideo id={'ugvjj33wg5'}>
                                <button className="btn blue margin-left-25">
                                    <span className="btn__mask"></span>
                                    <span className="btn__text arrow-btn">
                                        Watch Demo
                                        <img
                                            src={require('../images/home/right-arrow.png')}
                                            alt="right arrow"
                                        />
                                    </span>
                                </button>
                            </WistiaPopoverVideo>
                        </div>
                    </Container>
                    <Container className="container-2">
                        <div className="mobile-stats-view">
                            <img
                                src={require('../images/home/stats-mobile.png')}
                                alt="analytics chart"
                            />
                        </div>
                        <div className="stats-view-box">
                            <img
                                className="stat-img img-1"
                                src={require('../images/home/Group 7213.png')}
                                alt="pie chart for clicks"
                            />
                            <img
                                src={require('../images/home/Group 7084.png')}
                                className="stat-img img-2"
                                alt="white background containing search bar and quick add button"
                            />
                            <img
                                src={require('../images/home/Group 557.png')}
                                className="stat-img img-3"
                                alt="purple gradient banner displaying total revenue impact amount"
                            />
                            <img
                                src={require('../images/home/Group 7216.png')}
                                className="stat-img img-4"
                                alt="wedsite analytics graph"
                            />
                            <img
                                src={require('../images/home/Group 2460.png')}
                                className="stat-img img-5"
                                alt="summary of total leads,meetings,opportunities,and wins"
                            />
                            <img
                                src={require('../images/home/Group 4773.png')}
                                className="stat-img img-6"
                                alt="pie chart for opens"
                            />
                            <img
                                src={require('../images/home/Group 5014.png')}
                                className="stat-img img-7"
                                alt="monthly lead quota"
                            />
                        </div>
                    </Container>
                    <Container className="container-3 pink-gradient-background">
                        <Row className="struggle-row">
                            <Col className="centered-text max-width-900">
                                <h3 className="white-text">
                                    Created to overcome your struggles
                                </h3>
                                <p className="white-text wrapLineSib">
                                    GlassHive was developed with the care and
                                    attention of a team who has worked alongside
                                    MSPs of every size to help them achieve
                                    their sales and marketing dreams.
                                </p>
                                <p className="white-text wrapLineSib">
                                    And along the way, we kept seeing the same
                                    struggles. Visibility, accountability,
                                    manual data management — no matter the
                                    company, they had to put in the work to
                                    clear the same bottlenecks.
                                </p>
                                <p className="white-text wrapLineSib">
                                    Not anymore. Today, there's GlassHive.
                                </p>
                            </Col>
                        </Row>
                        <Row className="visibility-row flexed-mobile">
                            <Col className="max-width-500">
                                <h3 className="white-text centered-mobile">
                                    The visibility to solve your biggest
                                    problems
                                </h3>
                                <p className="white-text wrapLineSib centered-mobile">
                                    If you’re an IT service provider, you know
                                    what your problem is… it’s sales and
                                    marketing. If you want to fix that problem
                                    you need to approach it like any other
                                    problem: Identify the source.
                                </p>
                                <p className="white-text wrapLineSib centered-mobile">
                                    That’s why GlassHive’s entire foundation is
                                    built on visibility. You can finally have
                                    complete visibility over your sales and
                                    marketing team, making the source of the
                                    problem clear.
                                </p>
                            </Col>{' '}
                            <Waypoint
                                onEnter={() => {
                                    setAnimatePhone(true);
                                }}
                            />
                            <Col className="animated-phone-column max-width-500">
                                <AnimatedPhone animate={animatePhone} />
                            </Col>
                        </Row>
                        <Row className="automation-row padding-top-125px z-index-999">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelector(
                                            '.automation-row .mobile-img'
                                        )
                                        .classList.add('animate');
                                }}
                            />
                            <Col className="layered-img-col two-thirds rel">
                                <img
                                    className="mobile-img"
                                    src={require('../images/home/parallax-imgs.png')}
                                    alt="automation steps"
                                />
                                <Parallax
                                    y={[-30, 150]}
                                    tagOuter="div"
                                    className="layer-3 layered-images"
                                >
                                    <Image
                                        class="A-3"
                                        src={require('../images/home/layer 3.png')}
                                    />
                                    <Image
                                        class="B-3"
                                        src={require('../images/home/layer 3 B.png')}
                                    />
                                </Parallax>
                                <Parallax
                                    y={[-25, 10]}
                                    tagOuter="div"
                                    className="layer-2 layered-images"
                                >
                                    <Image
                                        class="A-2"
                                        src={require('../images/home/layer 2.png')}
                                    />
                                </Parallax>
                                <Parallax
                                    y={[-50, 80]}
                                    tagOuter="div"
                                    className="later-1 layered-images"
                                >
                                    <Image
                                        class="A-1"
                                        src={require('../images/home/layer 1.png')}
                                    />
                                </Parallax>
                            </Col>
                            <Col className="one-third">
                                <h3 className="white-text centered-mobile">
                                    Take back your time with automation
                                </h3>
                                <p className="white-text wrapLineSib centered-mobile">
                                    GlassHive has the power to automate pretty
                                    much everything. From sales follow-up to
                                    marketing plans, data entry, and beyond,
                                    GlassHive’s got the power to make your sales
                                    and marketing team super human.
                                </p>
                                <p className="white-text wrapLineSib centered-mobile">
                                    You've spent all this time empowering your
                                    service delivery team with automation.
                                    GlassHive is here to make them jealous.
                                </p>
                            </Col>
                        </Row>
                        <img
                            className="pink-shadow"
                            src={require('../svgs/home/ping-shadow.svg')}
                            alt="pink gradient background"
                        />
                    </Container>
                    <Container className="container-4 white-swish-mid transparent-background">
                        <Row>
                            <h2 className="purple-text centered-text margin-bottom-100 max-width-1100 margin-auto">
                                Innovative sales & marketing features for IT
                                service providers
                            </h2>
                        </Row>
                        <Row className="feature-card-row">
                            <Col>
                                <Waypoint
                                    onEnter={() => {
                                        document
                                            .querySelector('.first-card')
                                            .classList.add('animate');

                                        document
                                            .querySelector('.third-card')
                                            .classList.add('animate');
                                    }}
                                />
                                <FeatureCard
                                    title="Email marketing management"
                                    body="From a simple intuitive email builder that enables you to compose beautiful emails campaigns to fully automated marketing plans and journeys. GlassHive helps you master email marketing."
                                    src={require('../images/home/Drag and Drop.png')}
                                    videoSrc={require('../videos/email-marketing-management.mp4')}
                                    class="first-card reach"
                                />
                                <FeatureCard
                                    title="Tried and tested MSP marketing content"
                                    body="GlassHive offers a large library of marketing resources that cover the full gamut of IT services. All of it is automatically branded for your company so you can go to market in minutes."
                                    src={require('../images/home/Group 5761.png')}
                                    videoSrc={require('../videos/marketing-content.mp4')}
                                    class="third-card reach"
                                />
                            </Col>
                        </Row>
                        <Row className="feature-card-row">
                            <Col>
                                <Waypoint
                                    onEnter={() => {
                                        document
                                            .querySelector('.second-card')
                                            .classList.add('animate');

                                        document
                                            .querySelector('.fourth-card')
                                            .classList.add('animate');
                                    }}
                                />
                                <FeatureCard
                                    title="Automated activity tracking"
                                    body="Even if you don't love Outlook, chances are you rely on it to handle vital communications. GlassHive will automatically grab those activities and enter them in GlassHive for you."
                                    src={require('../images/home/1to1.png')}
                                    videoSrc={require('../videos/activity-tracking.mp4')}
                                    class="abs second-card reach"
                                />
                                <FeatureCard
                                    title="Bulk sales actions"
                                    body="GlassHive takes the grunt work out of sales follow up. Use GlassHive’s powerful bulk actions to follow up thousands of leads in under 5 minutes."
                                    src={require('../images/home/Card View – 10.png')}
                                    videoSrc={require('../videos/bulk-sales-actions.mp4')}
                                    class="abs fourth-card reach"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className="demo-videos"></div>
                        </Row>
                    </Container>
                    <Container className="container-5 purple-gradient-background">
                        <Row>
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelector(
                                            '.circle-image-container'
                                        )
                                        .classList.add('animate');
                                }}
                            />
                            <Col className="one-half circle-image-container">
                                <img
                                    className="mobile-img-2"
                                    src={require('../images/home/Integrations-Graphic-Homepage.png')}
                                    alt="circle containing integration icons"
                                />
                                <img
                                    className="main-circle"
                                    src={require('../images/home/circles/Ellipse 2058.png')}
                                    alt="orange gradient circle"
                                />
                                <img
                                    className="abs dash-1"
                                    src={require('../images/home/circles/dashes-1.png')}
                                    alt="xsmall circle with dashed lines"
                                />
                                <img
                                    className="abs dash-2"
                                    src={require('../images/home/circles/dashes-2.png')}
                                    alt="small circle with dashed lines"
                                />
                                <img
                                    className="abs dash-3"
                                    src={require('../images/home/circles/dash-3.png')}
                                    alt="medium circle with dashed lines"
                                />
                                <img
                                    className="abs dash-4"
                                    src={require('../images/home/circles/dash-4.png')}
                                    alt="large circle with dashed lines"
                                />
                                <img
                                    className="abs dash-5"
                                    src={require('../images/home/circles/dashes-5.png')}
                                    alt="xlarge circle with dashed lines"
                                />
                                <img
                                    className="abs group-2696"
                                    src={require('../images/home/glasshive.png')}
                                    alt="glasshive logo"
                                />
                                <img
                                    className="abs group-2699"
                                    src={require('../images/home/cw.png')}
                                    alt="connectwise logo"
                                />
                                <img
                                    className="abs group-2701"
                                    src={require('../images/home/logo.png')}
                                    alt="office logo"
                                />
                                <img
                                    className="abs group-2702"
                                    src={require('../images/home/gmail.png')}
                                    alt="gmail logo"
                                />
                                <img
                                    className="abs group-2703"
                                    src={require('../images/home/twitter.png')}
                                    alt="twitter logo"
                                />
                                <img
                                    className="abs group-2704"
                                    src={require('../images/home/facebook.png')}
                                    alt="facebook logo"
                                />
                                <img
                                    className="abs group-2705"
                                    src={require('../images/home/sf.png')}
                                    alt="salesforce logo"
                                />
                                <img
                                    className="abs group-7169"
                                    src={require('../images/home/circles/Group 7169.png')}
                                    alt="large white circle"
                                />
                                <img
                                    className="abs path-3885"
                                    src={require('../images/home/circles/Path 3885.png')}
                                    alt="small white circle"
                                />
                                <img
                                    className="abs path-3886"
                                    src={require('../images/home/circles/Path 3886.png')}
                                    alt="tiny white circle"
                                />
                                <img
                                    className="abs rect-2837"
                                    src={require('../images/home/circles/Rectangle 2837.png')}
                                    alt="medium white circle"
                                />
                                <img
                                    className="abs rect-2838"
                                    src={require('../images/home/circles/Rectangle 2838.png')}
                                    alt="small white circle"
                                />
                                <img
                                    className="abs rect-2840"
                                    src={require('../images/home/circles/Rectangle 2840.png')}
                                    alt="tiny white circle"
                                />
                                <img
                                    className="abs rect-3138"
                                    src={require('../images/home/circles/Rectangle 3138.png')}
                                    alt="large white circle"
                                />
                            </Col>
                            <Col className="one-third lose-the-migration">
                                {' '}
                                <h3 className="white-text centered-mobile">
                                    Lose the migration anxiety
                                </h3>
                                <p className="white-text wrapLineSib centered-mobile">
                                    Instead of outright replacing what you
                                    currently use, our integrations let your
                                    favorite apps ride shotgun in your sales and
                                    marketing journey.
                                </p>
                                <p className="white-text wrapLineSib centered-mobile">
                                    Keep them all or replace what you want when
                                    you want.
                                </p>
                            </Col>
                        </Row>
                        <div className="logos-container deep-purple-background centered-text">
                            <h3 className="white-text max-width-700 margin-auto">
                                Simplify marketing relationships through
                                platform unity
                            </h3>
                            <p className="white-text max-width-650 margin-auto wrapLineSib">
                                GlassHive takes the complexity out of vendor
                                marketing relationships by creating a single
                                platform that benefits both vendors and
                                partners.
                            </p>
                            <p className="white-text max-width-650 wrapLineSib margin-auto margin-bottom-50">
                                Don't believe us? These companies do.
                            </p>
                            <div className="logo-images">
                                <Waypoint
                                    onEnter={() => {
                                        document
                                            .querySelector('.logo-images')
                                            .classList.add('animate');
                                    }}
                                />
                                <div className="one-fourth first">
                                    <img
                                        src={require('../images/home/Group 11.png')}
                                        alt="tridigital logo"
                                    />
                                </div>
                                <div className="one-fourth second">
                                    <img
                                        src={require('../images/home/path24.png')}
                                        alt="ingram logo"
                                    />
                                </div>
                                <div className="one-fourth third">
                                    <img
                                        src={require('../images/home/757px-Apple_logo_white.png')}
                                        alt="apple logo"
                                    />
                                </div>
                                <div className="one-fourth fourth">
                                    <img
                                        src={require('../images/home/Lenovo_logo.png')}
                                        alt="lenovo logo"
                                    />
                                </div>
                                <div className="one-third seventh">
                                    <img
                                        src={require('../images/home/fortinet-logo-white.png')}
                                        alt="fortinet logo"
                                    />
                                </div>
                                <div className="one-third sixth">
                                    <img
                                        src={require('../images/home/pronto-marketing.png')}
                                        alt="pronto logo"
                                    />
                                </div>
                                <div className="one-third fifth">
                                    <img
                                        src={require('../images/home/Group 5857.png')}
                                        alt="trend micro logo"
                                    />
                                </div>
                                <div className="one-third eighth">
                                    <img
                                        src={require('../images/home/Group 7140.png')}
                                        alt="ibm logo"
                                    />
                                </div>
                                <div className="one-third nineth">
                                    <img
                                        src={require('../images/home/Group 5860.png')}
                                        alt="storagecraft logo"
                                    />
                                </div>
                                <div className="one-third tenth">
                                    <img
                                        src={require('../images/home/compliancy group logo.png')}
                                        alt="compliancy group logo"
                                    />
                                </div>
                            </div>
                        </div>
                        <AnimatedBlob
                            cls="canvas-1"
                            fill="rgba(255,137,157, .25)"
                        />
                        <AnimatedBlob
                            cls="canvas-2"
                            fill="rgba(255,137,157, .25)"
                        />
                    </Container>
                </div>
            </Layout>
        </ParallaxProvider>
    );
};

export default IndexPage;
