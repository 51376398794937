import React from "react"

import "./style.scss"

class FeatureCard extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  render() {
    return (
      <div
        className={"feature-card " + (this.props.class ? this.props.class : "")}
      >
        <h4>{this.props.title}</h4>
        <p className="wrapLineSib">{this.props.body}</p>
        <video
          className="video hide-on-mobile"
          src={this.props.videoSrc}
          muted
          playsInline
          autoPlay
          loop
        >
          <p className="wrapLineSib">
            If you are reading this, it is because your browser does not
            support the HTML5 video element.
          </p>
        </video>
        <img className="image show-on-mobile" src={this.props.src} alt={"Feature - " + this.props.title} />
      </div>
    )
  }
}

export default FeatureCard
