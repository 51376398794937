import React, { useEffect } from "react"
import "./style.scss"

const AnimatedPhone = ({ animate }) => {
  return (
    <div className={`animated-phone ${ animate ? 'animate' : '' }`}>
      <img
        className="phone-image"
        src={require("./phone.png")}
        alt="phone"
      />
      <img
        className="aggregates-image"
        src={require("./aggregates.png")}
        alt="aggregate metrics"
      />
      <img
        className="quota-image"
        src={require("./quota.png")}
        alt="quota"
      />
    </div>
  )
}

export default AnimatedPhone
